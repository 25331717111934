.seqrops-property-console-wrapper{
    height: calc(100vh - 100px);
    margin-top: 30px;
    padding: 20px 15px;
    border: 1px solid #E7E7E7;
    background-color: white;
    border-radius: 5px;


    .seqrops-property-console-title-section{

        h6{
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .seqrops-close-btn{
        background: none;
        border: none;
        text-align: start;
    }
}

.seqrops-property-form-wrapper {
    background-color: #FAFCFF;
    height: calc(100vh - 234px);
    margin-top: 8px;
    overflow: auto;
    border: 1px solid #E8F7FF;
    border-radius: 5px;
}
.seqrops-property-console-save-button-section {
    text-align: right;
}
::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background: #fafafa; /* color of the tracking area */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #ccc; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid #fafafa; /* creates padding around scroll thumb */
  }

  .bg-property-sec {
    background: #F2F4F7;
    padding: 8px;
    margin-top: 7px;
    font-size: 14px;
    border-radius: 4px;
    p{
        margin: 0;
        &:first-child{
            margin-right: 15px;
        }
    }
}
.seqrops-property-type-selection-section p {
    border: none;
    background: transparent;
    cursor: pointer;
}

.seqrops-property-type-selection-section .active {
    border-bottom: 3px solid #60ABFB;
    font-weight: bold;
}

.seqrops-file-download-title-section {
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #dfe2ef;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    h6{
      margin: 0;
    }
  }
  .seqrops-property-checkbox {
    display: flex;
      align-items: baseline;
  
      .form-label{
        white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
      }
  
  }
  .seqrops-property-type-title span{
    text-transform: capitalize;
  }