.listDynamicData {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

.dynmicList {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  .form-group {
    input {
      width: 30vw;
    }
  }
}
.dynmicList div {
  margin: 0 18px 0 10px;
}

.form-group {
  display: grid;
}

.form-group label {
  font-size: 12px;
}

.form-group input {
  width: 263px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  color: rgb(54, 52, 52);
  background-color: #e9ecef;
  font-size: 12px;
}
.seqrops-alarm-form-section input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  color: rgb(54, 52, 52);
  background-color: #e9ecef;
  font-size: 12px;
}

.seqrops-basic-form-head-section {
  padding: 5px 0 5px;
  padding-left: 10px;
  border: 1px solid #e8f7ff;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  background: #fafcff;

  h4 {
    padding-top: 10px;
    font-weight: 300;
  }

  p {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
  }
}

.alarm-value {
  margin-top: -5px;
}

.seqrops-alarm-form-section {
  margin-left: 22px !important;
}

.seqrops-alarm-form-wrapper {
  max-height: 189px;
  overflow: auto;
  background-color: #fafcff;
}

.form-control {
  padding: 4px 10px !important;
}

.form-control.form-control--multiLine {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
