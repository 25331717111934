    .seqrops-pagination-wrapper{
    display: flex; 
}

.seqrops-pagination-wrapper{
    .seqrops-pageNo-section {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
    }
    .css-jtlhu6-MuiTablePagination-root:last-child {
        margin: 0 auto;
    }
  .css-o9ey65{
    margin: 0 auto !important;
  }
}