.myTable th,
td {
  border: 1px solid rgb(196, 192, 192);
  color: rgb(88, 86, 86);
  padding: 15px;
  text-align: left;
}
.seqrops-instance-table-wrapper {
  height: calc(100vh - 260px);
  overflow: auto;
}
.instance-no-result {
  td {
    text-align: center;
  }
}

.operations {
  width: 30px;
  span{
    margin-right: 5px;
    img{
      width: 17px;
    }

  }
}
.seqrops-instance-table tr td {
  max-width: 400px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.seqrops-instance-table table th {
  background: #f1f8ff;
  top: 0;
  position: sticky;
}