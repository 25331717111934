.seqrops-tree-console-wrapper {
  background-color: #fcfcfc;
  height: calc(100vh - 100px);
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  .seqrops-tree-console-main {
    height: calc(100vh - 180px);
    overflow: auto;
  }
}

.seqrops-tree-console-title-section {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding-bottom: 10px;
  h5 {
    padding-left: 0;
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 19px;
  }
}

@import "assets/styles/variables.scss";
//
$border: #ddd;
$border-hover: #aaa;
$bg-hover: #eee;
$text: #888;
$text-hover: #000;
$ident: 20px;
$left: -($ident);
ul {
  margin-left: $ident;
}

.seqrops-tree-model-section {
  // padding-left: 60px;
  // padding-top: 10px;
}

.seqrops-tree-model-title-section {
  margin-top: 20px;
  margin-left: 15px;

  h4 {
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: bold;
    color: #454545;
  }
}

//
.wtree {
  li {
    list-style-type: none;
    margin: 10px 0 10px 10px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: $left;
      border-left: 1px solid $border;
      border-bottom: 1px solid $border;
      width: $ident;
      height: 15px;
    }
    &:after {
      position: absolute;
      content: "";
      top: 5px;
      left: $left;
      border-left: 1px solid $border;
      border-top: 1px solid $border;
      width: $ident;
      height: 100%;
    }
    &:last-child:after {
      display: none;
    }
    span {
      display: block;
      border: 1px solid $border;
      padding: 10px;
      color: $text;
      text-decoration: none;
    }
  }
}

.wtree {
  li {
    span {
      &:hover,
      &:focus {
        background: $bg-hover;
        color: $text-hover;
        border: 1px solid $border-hover;
        & + ul {
          li {
            span {
              background: $bg-hover;
              color: $text-hover;
              border: 1px solid $border-hover;
            }
          }
        }
      }

      &:hover + ul,
      &:focus + ul {
        li {
          &:after,
          &:before {
            border-color: $border-hover;
          }
        }
      }
    }
  }
}
.error-message-section1 {
  background-color: #ffe9e9;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  text-align: left;
  margin-left: -12px;
  margin-bottom: 0;
  max-width: max-content;
  min-width: min-content;
  color: red;
  font-size: 11px;
}
