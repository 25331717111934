body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
background: #FAFDFF !important;

}

.form-control:focus {
    border-color: #eee !important;
    box-shadow: none !important;
}
.form-label {
  font-size: 14px;
  padding: 0 !important;
  margin-bottom: 0.2rem !important;  
  text-transform: capitalize;
}

button.sequrops_btn_primary {
  border-color: #60ABFB;
  background: #60ABFB;
}

/*table common style */

.bg-table .seqrops-instance-search-section input {
  background: #F7F8FA;
  box-shadow: none !important;
}

.bg-table {
  background: #fff;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 6%);
}
.bg-table .seqrops-instance-head-section button {
  border: none;
  padding: 7px;
  line-height: 4px;
  background: none !important;
}
.seqrops-instance-table table th, td {
  border: 1px solid #edeef0 !important;
  color: #606F8E;
  padding: 10px !important;
  text-align: left;
  font-size: 14px !important;
}
.MuiTablePagination-toolbar p {
  margin: 0 !important;
}
.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0 !important; 
}

::ng-deep .MuiInputBase-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out;
  border: 1px solid rgb(218, 215, 215) !important;

}
/* :focus{
  border-color: #eee !important;
  box-shadow: 0 0 0 0.25rem rgb(240 248 255) !important;
} */
.seqrops-property-form-wrapper input{
  background: white;
}
.btn.btn-outline {
  background: transparent;
  border-color: #7c7777;
}
.btn.btn-outline:hover {
  background: #eee;
  border: 1px solid #ddd;
}
.btn {
  font-size: 14px !important;
  width: 90px;
}
.seqrops-close-btn {
  background: none;
  border: none;
  text-align: start;
}


/*button */
.btn_unsaved{
  display: flex;
  gap: 10px;
  }
  .btn_unsaved .seqrops-btn {
    height: 33px;
    width: 117px;
    font-size: 12px;
  }
  
  .btn_unsaved .seqrops-btn-small {
    line-height: 10px;
    background: #60abfb;
    border: 1px solid #60abfb;
    width: auto;
    color: white;
    border-radius: 3px;
    height: 16px;
    font-size: 12px;
  }
  
  .btn_unsaved .btn-outline {
    background: transparent;
    border: 1px solid #363f5e;
    border-radius: 3px;
    color: #363f5e;
  
  
  }
  
  .btn_unsaved  .btn-outline :hover {
    background-color: #f9f9f9;
    color: #363f5e;
  }
  
  .btn_unsaved .btn-fill {
    background-color: #60abfb;
    border: 1px solid #60abfb;
    border-radius: 3px;
    color: #fff;
  
  }
  
  .btn_unsaved .btn-fill :hover {
    background-color: #2e8ff5;
  }
  
  .btn_unsaved .MuiDialogActions-spacing .css-hlj6pa-MuiDialogActions-root{
  padding: 20px 26px;
  }