.seqrops-property-form-wrapper {
  background-color: #fafcff;
  height: 100%;
  margin-top: 10px;
  img {
    width: 2vh;
  }
}
.seqrops-property-fields {
  margin-top: 10px;
}

.seqrops-static-property-section,
.seqrops-dynamic-property-section {
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  margin-top: 15px;
}
.seqrops-property-adding-form-head-section {
  display: flex;
  justify-content: flex-end;

  p {
    margin: 0;
    font-size: 16px;
    color: #606f8e;
    font-weight: bold;
  }
}
.seqrops-property-type-title {
  span {
    background: #e5efff;
    border: 1px solid #c5e1ed;
    padding: 0px;
    border-radius: 100px;
    width: 60px;
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 700;
    color: #606f8e;
  }
}
.seqrops-property-delete-icon-section {
  display: flex;
  justify-content: flex-end;
}
.seqrops-property-edit-icon-section {
  display: flex;
  justify-content: flex-end;
}

.border-seqrops-property-form {
  border: 1px solid #e8f7ff;
  padding: 8px;
}
.seqrops-file-download-title-section {
  display: flex;
}

.seqrops-file-download-section {
  img {
    width: 18px;
    &:last-child {
      margin-left: 15px;
    }
  }
}

.seqrops-property-type-title span {
  text-align: center;
}


.card {
  background: black;
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.no-dynamic-property {
  position: relative;
  color: #6B7A99;
  left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  text-align: center;
  margin: 0 auto;
  right: 0;
  top: 10em;
}

.seqrops-property-fields-file {
  background: white;
  border: 1px solid #e8e8e8;
  padding: 8px;
  border-radius: 6px;
  label{
    margin-right: 10px;
  }
}