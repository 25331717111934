@media (max-width: 1920px) {
  .seqrops-model-list-item-container {
    background-color: #fff;
    color: #333;
    font-weight: bold;
    border-radius: 5px;
    // min-width: 300px;
    // width: 300px;
    // height: 11vh;
    position: relative;
    border: 1px solid #efefef;
    overflow: hidden;
    padding: 1.5vw 0.5vw;
    cursor: pointer;

    .seqrops-modelItem {
      //justify-content: center;
      align-items: center;
      color: #5b6781;
      font-size: 1.1vw;
      //padding-bottom: 20px;
      justify-content: space-between;

      // padding-top: 1rem;
      &label {
        cursor: pointer !important;
      }
    }

    .seqrops-modelDescription {
      color: #5b6781;
      font-size: 1vw;
      font-weight: 300;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      justify-content: space-between;
      overflow: hidden;
      width: 100%;
      word-wrap: break-word;
      cursor: pointer;
    }
    .seqrops-modelInstanceCount {
      color: #5b6781;
      font-size: 13px;
      font-weight: 300;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      justify-content: space-between;
      overflow: hidden;
      width: 180px;
      word-wrap: break-word;
      cursor: pointer;
    }
    .seqrops-modelInstanceCountLabel {
      margin-right: 55px;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer !important;
    }

    .list-item-instance-count {
      // color: white;
      color: #5bc1d1;
      font-size: 1.2vw;
      position: absolute;
      top: 0.1rem;
      right: 0.3rem;
    }

    .seqrops-modelIsEquipment {
      position: absolute;
      right: 0;
      right: 20px;
      top: 10px;
      z-index: 99;
      img {
        width: 22px;
      }
    }

    .modelBox {
      text-decoration: none;
      cursor: pointer;
      //    &:hover{
      //     background: #d7f3ff;
      //    }
    }

    .modelIcon {
      background: #fff;
      // margin-left: 10px;
      // height: 11vh;
      display: flex;
      align-items: center;
      padding-right: 0.5vw;
      img {
        width: 3.5vw;
        height: 3.5vw;
        font-size: 10px;
        color: white;
      }
    }
    .seqrops-model-options-section {
      justify-content: flex-end;
      align-items: center;
      height: 39px;

      &:hover {
        background: #c6ebff;
      }
      img {
        padding: 5px;
        width: 29px;
      }

      button {
        border: none;
        background: none;
      }
    }
  }
  .seqrops-view-row {
    cursor: pointer;
  }

  .seqrops-home-model-list-section {
    padding-bottom: 80px;
  }
  .seqrops-home-model-list {
    width: calc(100% / 3);
  }

  // .seqrops-model-list-item-container {
  //   &::after {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     width: 100%;
  //     height: 100%;
  //     background: #62acfa;
  //     clip-path: circle(157px at 123% -46%);
  //     transition: .5s ease-in-out;
  //     border-radius: 5px;
  //     z-index: 0;

  //   }
  // }
  // .seqrops-model-list-item-container:hover:after {     clip-path: circle(200px at 122% -48%);
  //   opacity: 0.2; }
  .z-index-9 {
    z-index: 999;
  }
}
@media (min-width: 1921px) {
  .seqrops-model-list-item-container {
    background-color: #fff;
    color: #333;
    font-weight: bold;
    border-radius: 5px;
    // min-width: 300px;
    // width: 300px;
    // height: 11vh;
    position: relative;
    border: 1px solid #efefef;
    overflow: hidden;
    padding: 1.5rem 0.5rem;
    .seqrops-modelItem {
      //justify-content: center;
      align-items: center;
      color: #5b6781;
      font-size: 16px;
      //padding-bottom: 20px;
      justify-content: space-between;
      // padding-top: 1rem;
    }

    .seqrops-modelDescription {
      color: #5b6781;
      font-size: 13px;
      font-weight: 300;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      justify-content: space-between;
      overflow: hidden;
      width: 100%;
      word-wrap: break-word;
    }
    .seqrops-modelInstanceCount {
      color: #5b6781;
      font-size: 13px;
      font-weight: 300;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      justify-content: space-between;
      overflow: hidden;
      width: 180px;
      word-wrap: break-word;
    }
    .seqrops-modelInstanceCountLabel {
      margin-right: 55px;
      font-size: 14px;
      font-weight: 400;
    }

    .list-item-instance-count {
      // color: white;
      color: #5bc1d1;
      font-size: 20px;
      position: absolute;
      top: 0.1rem;
      right: 0.3rem;
    }

    .seqrops-modelIsEquipment {
      position: absolute;
      right: 0;
      right: 20px;
      top: 10px;
      z-index: 99;
      img {
        width: 22px;
      }
    }

    .modelBox {
      text-decoration: none;
      cursor: pointer;
      //    &:hover{
      //     background: #d7f3ff;
      //    }
    }

    .modelIcon {
      background: #fff;
      padding-right: 0.5rem;
      // margin-left: 10px;
      // height: 11vh;
      display: flex;
      align-items: center;

      img {
        width: 3rem;
        height: 3rem;
        font-size: 10px;
        color: white;
      }
    }
    .seqrops-model-options-section {
      justify-content: flex-end;
      align-items: center;
      height: 39px;

      &:hover {
        background: #c6ebff;
      }
      img {
        padding: 5px;
        width: 29px;
      }

      button {
        border: none;
        background: none;
      }
    }
  }
  .seqrops-view-row {
    cursor: pointer;
  }

  .seqrops-home-model-list-section {
    padding-bottom: 80px;
  }
  .seqrops-home-model-list {
    width: calc(100% / 3);
  }

  .z-index-9 {
    z-index: 999;
  }
}
