@import "assets/styles/variables.scss";

.seqrops-home-search-section {
  padding: 10px;

  input {
    width: 100%;
    font-size: 14px;
    background-color: white;
    border: 1px solid #eeecec !important;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    margin-right: 10px;
    :focus {
      border-color: #eeecec !important;
    }
  }

  button {
    background-color: white;
    border-radius: 4px;
    padding: 12px 14px !important;
    border: 1px solid #ffffffee;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.06);
  }
}

.seqrops-inital-page-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.seqrops-no-model-image-text-section {
  padding-top: 10px;
  color: #a6a6b0;
}

.seqrops-create-model-button-section {
  padding-top: 20px;

  .initialscreen-createBtn {
    border: 1px solid $createBtnOutline;
    padding: 15px;
    border-radius: 3px;
    background-color: white;
    color: $createBtnOutline;
    transition: all 0.5s $ease;
    &:hover {
      border-color: transparent;
      color: white;
      background-color: $createBtnOutline;
    }
  }
}
.seqrops-no-model-image-section {
  display: flex;
  justify-content: center;
  img {
    width: 280px;
  }
}
.seqrops-no-model-image-text-section {
  font-size: 15px;
}
.seqrops-no-model-image-text-section {
  display: flex;
  justify-content: center;
}

.seqrops-create-model-button-section {
  display: flex;
  justify-content: center;
}
.seqrops-pagination-wrapper .MuiTablePagination-root {
  position: relative;
  left: 24vw;
}
// .overlayFilter {
//   width: 150px;
// }

.p-overlaypanel {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0),
    0px 24px 38px 3px rgba(0, 0, 0, 0), 0px 9px 46px 8px rgba(0, 0, 0, 0.08) !important;
}

.filterImg {
  width: 22px;
}
.filterDropDown {
  label {
    font-size: 14px;
    line-height: 23px;
    font-weight: 500;
    cursor: pointer;
  }

  .allDropDown {
    padding: 8px 8px 4px 12px;
  }
  .allDropDown:hover {
    // background-color: #e5e5ec;
    // width: 150px;
    label {
      color: #60abfb;
    }
  }
  .equipmentDropDown {
    padding: 8px 8px 4px 12px;
  }
  .equipmentDropDown:hover {
    // background-color: #e5e5ec;
    // width: 150px;
    label {
      color: #60abfb;
    }
  }
}
.seqropsfilter {
}
.seqropsfilter:hover {
  background-color: #dcebfa;
}
