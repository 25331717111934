.App {
  font-family: "Roboto", sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Toastify__toast-container {
  min-width: 400px;
}

/*loader */

.grid {
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
}

.loader::before {
  content: "";
  background: rgba(242, 235, 235, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  height: calc(100vh - 0px);
  z-index: 0;
}

.loader {
  width: 50vw;
  border: 1px solid mistyrose;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

#ld1 {
  position: relative;
  transform: rotate(45deg);
}

#ld1 div {
  height: 20px;
  width: 20px;
  /* background: #FE4A49; */
  background: #0072bb;

  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#ld1 div:nth-child(1) {
  animation: ld1_div1 1s ease-in-out infinite;
}

#ld1 div:nth-child(2) {
  animation: ld1_div2 1s ease-in-out infinite;
}

#ld1 div:nth-child(3) {
  animation: ld1_div3 1s ease-in-out infinite;
}

@keyframes ld1_div1 {
  0% {
    top: 52.5px;
    /* background: #FE4A49; */
    background: #0072bb;
  }

  50% {
    top: -52.5px;
    /* background: #59CD90; */
    background: #0072bb;
  }

  100% {
    top: 52.5px;
    /* background: #009FB7; */
    background: #0072bb;
  }
}

@keyframes ld1_div2 {
  0% {
    right: 52.5px;
    /* background: #FE4A49; */
    background: #0072bb;
  }

  50% {
    right: -52.5px;
    /* background: #FED766; */
    background: #0072bb;
  }

  100% {
    right: 52.5px;
    /* background: #59CD90; */
    background: #0072bb;
  }
}

@keyframes ld1_div3 {
  0% {
    left: 52.5px;
    /* background: #FE4A49; */
    background: #0072bb;
  }

  50% {
    left: -52.5px;
    /* background: #D91E36; */
    background: #0072bb;
  }

  100% {
    left: 52.5px;
    /* background: #FE4A49; */
    background: #0072bb;
  }
}

#ld2 {
  display: flex;
  flex-direction: row;
}

#ld2 div {
  height: 20px;
  width: 5px;
  /* background: #FE4A49; */
  background: #0072bb;

  margin: 3px;
  border-radius: 25px;
}

#ld2 div:nth-child(1) {
  animation: ld2 1s ease-in-out infinite 0s;
}

#ld2 div:nth-child(2) {
  animation: ld2 1s ease-in-out infinite 0.1s;
}

#ld2 div:nth-child(3) {
  animation: ld2 1s ease-in-out infinite 0.2s;
}

#ld2 div:nth-child(4) {
  animation: ld2 1s ease-in-out infinite 0.3s;
}

#ld2 div:nth-child(5) {
  animation: ld2 1s ease-in-out infinite 0.4s;
}

#ld2 div:nth-child(6) {
  animation: ld2 1s ease-in-out infinite 0.5s;
}

#ld2 div:nth-child(7) {
  animation: ld2 1s ease-in-out infinite 0.6s;
}

@keyframes ld2 {
  0% {
    transform: scaleY(1);
    /* background: #FED766; */
    background: #0072bb;
  }

  25% {
    /* background: #009FB7; */
    background: #0072bb;
  }

  50% {
    transform: scaleY(2);
    /* background: #59CD90; */
    background: #0072bb;
  }

  75% {
    /* background: #FE4A49; */
    background: #0072bb;
  }

  100% {
    transform: scaleY(1);
    /* background: #D91E36; */
    background: #0072bb;
  }
}

#ld3 {
  position: relative;
  animation: outercontainer 4s linear infinite;
}

#ld3 div {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#ld3 div:nth-child(1) {
  top: 20px;
  /* background: #59CD90; */
  background: #0072bb;

  animation: ld3_div1 2s linear infinite;
}

#ld3 div:nth-child(2) {
  top: -20px;
  /* background: #D91E36; */
  background: #0072bb;

  animation: ld3_div2 2s linear infinite;
}

#ld3 div:nth-child(3) {
  left: 20px;
  /* background: #F39237; */
  background: #0072bb;

  animation: ld3_div4 2s linear infinite;
}

#ld3 div:nth-child(4) {
  left: -20px;
  /* background: #0072BB; */
  background: #0072bb;

  animation: ld3_div3 2s linear infinite;
}

@keyframes outercontainer {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ld3_div1 {
  0% {
    top: 20px;
  }

  25% {
    top: 0;
  }

  50% {
    top: 20px;
  }

  75% {
    top: 0;
  }

  100% {
    top: 20px;
  }
}

@keyframes ld3_div2 {
  0% {
    top: -20px;
  }

  25% {
    top: 0;
  }

  50% {
    top: -20px;
  }

  75% {
    top: 0;
  }

  100% {
    top: -20px;
  }
}

@keyframes ld3_div3 {
  0% {
    left: -20px;
  }

  25% {
    left: 0;
  }

  50% {
    left: -20px;
  }

  75% {
    left: 0;
  }

  100% {
    left: -20px;
  }
}

@keyframes ld3_div4 {
  0% {
    left: 20px;
  }

  25% {
    left: 0;
  }

  50% {
    left: 20px;
  }

  75% {
    left: 0;
  }

  100% {
    left: 20px;
  }
}

#ld4 {
  position: relative;
  display: flex;
  width: 25%;
  justify-content: space-between;
}

#ld4 div {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  /* background: #D91E36; */
  background: #0072bb;
}

#ld4 div:nth-child(1) {
  animation: ld4 3s linear infinite 0s;
}

#ld4 div:nth-child(2) {
  animation: ld4 3s linear infinite 0.15s;
}

#ld4 div:nth-child(3) {
  animation: ld4 3s linear infinite 0.3s;
}

#ld4 div:nth-child(4) {
  animation: ld4 3s linear infinite 0.45s;
}

@keyframes ld4 {
  0% {
    opacity: 0;
    transform: scale(0.3);
    /* background: #59CD90; */
    background: #0072bb;
  }

  25% {
    opacity: 1;
    transform: scale(1.8);
    /* background: #0072BB; */
    background: #0072bb;
  }

  50% {
    opacity: 0;
    transform: scale(0.3);
    /* background: #FE4A49; */
    background: #0072bb;
  }

  75% {
    opacity: 1;
    transform: scale(1.8);
    /* background: #FED766; */
    background: #0072bb;
  }

  100% {
    opacity: 0;
  }
}

.loader-row {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
  margin: 0 auto;
}

.overlayFilterNew {
  .p-overlaypanel-content {
    padding: 0px !important;
    width: auto !important;
    /* background-color: green !important; */
    max-width: 200px;
  }
}

#light {
  background: #f9fdff !important;
}

#dark {
  .seqrops-inital-page-wrapper {
    background: #111217 !important;
  }

  background: #111217 !important;

  .seqrops-dashboard-tile-wrapper {
    background: #181b1f !important;
    border-color: #24272d;
    box-shadow: none;
  }

  .seqrops-options-text {
    color: #b9bdce !important;
  }

  .seqrops-dashboard-tile-wrapper span {
    color: #b9bdce !important;
  }

  .seqrops-navbar-wrapper {
    background: #24272d !important;
    color: white !important;
  }

  .p-overlaypanel-content {
    background: #24272d !important;
    color: white !important;
  }

  .logout-section span {
    color: white !important;
  }

  .target-app-container {
    background: #24272d !important;
    color: white !important;
    border-color: #24272d;
  }

  .seqrops-username-box {
    .seqrops-username-text {
      background-color: #081f6b;
      color: #56bbff;
    }

    .seqrops-username {
      color: #b9bdce;
    }
  }

  .dark-navbar {
    background: #24272d !important;
  }

  .seqrops-options-text {
    color: #b9bdce !important;
  }

  .seqrops-home-search-section {
    input {
      background-color: #111217 !important;
      border: 1px solid #2e374b !important;
      color: #ccccdc !important;
    }
  }

  .seqrops-home-search-section input::placeholder {
    color: #ccccdc !important;
  }

  .seqropsfilter {
    background-color: #111217 !important;
    border: 1px solid #2e374b !important;
  }

  .seqrops-pageNo-section {
    background-color: #111217 !important;
  }

  .MuiTablePagination-displayedRows,
  .MuiButtonBase-root,
  .MuiTablePagination-selectLabel,
  .MuiSvgIcon-root,
  .MuiSelect-select {
    color: #b9bdce !important;
  }

  .seqrops-model-list-item-wrapper {
    border: 1px solid #24272d !important;
  }

  .seqrops-model-list-item-container {
    background: #181b1f !important;
    border: 1px solid #24272d !important;
  }

  .seqrops-modelItem-desc-wrapper {
    span {
      color: #b9bdce !important;
    }
  }

  .seqrops-instance-table {
    color: #b9bdce !important;
    border: 1px solid #2e374b !important;
  }

  .seqrops-instance-table-first-tr th {
    background-color: #2e374b !important;
    border: 1px solid #181b1f !important;
    color: #b9bdce !important;
  }

  .seqrops-instance-table table {
    border: 1px solid #2e374b !important;
    color: #b9bdce !important;
  }

  .seqrops-instance-table table tr {
    &:nth-of-type(2n) {
      background-color: #2e374b !important;
      color: #b9bdce !important;
      border: 1px solid #181b1f !important;
    }
  }

  .seqrops-instance-table table th {
    border: 1px solid #2e374b !important;
    text-align: left;
    padding: 6px;
    font-size: 13px;
    color: #b9bdce !important;
  }

  .seqrops-instance-table table td {
    text-align: left;
    font-size: 13px;
    border: 1px solid #2e374b !important;
    padding: 6px;
    color: #b9bdce !important;
  }

  .seqrops-instance-search-section input {
    background-color: #181b1f !important;
    border: 1px solid #2e374b !important;
  }

  .seqrops-instance-search-section {
    .search_btn {
      background-color: #181b1f !important;
      border: 1px solid #2e374b !important;
    }
  }

  .seqrops-instance-search-section input::placeholder {
    color: #ccccdc !important;
  }

  .seqrops-instance-title-section p {
    color: #ccccdc !important;
  }

  .modelIcon {
    background: #181b1f !important;
  }

  .seqrops-tree-console-wrapper {
    background: #111217 !important;
    border: 1px solid #24272d !important;
  }

  .seqrops-tree-console-title-section {
    border-bottom: 1px solid #24272d;

    h5 {
      color: #ccccdc !important;
    }
  }

  .create_pagination {
    span {
      b {
        color: #858694 !important;
      }
    }
  }

  .seqrops-property-console-wrapper {
    background: #111217 !important;
    border: 1px solid #24272d !important;
  }

  .seqrops-property-console-save-button-section {
    .seqrops-property-save-btn {
      color: #ccccdc !important;
    }

    .seqrops-property-save-btn:hover {
      border: 1px solid #60abfb !important;
      color: #60abfb !important;
      background-color: #24272d !important;
    }
  }

  .property-console-head-section {
    background: #24272d !important;
    border: 1px solid #24272d !important;
    color: #ccccdc !important;
  }

  .seqrops-property-form-wrapper {
    background: #24272d !important;
    border: 1px solid #24272d !important;
    color: #ccccdc !important;
  }

  .border-seqrops-property-form {
    border: 1px solid #24272d !important;
  }

  .seqrops-property-adding-form-head-section p {
    color: #858694 !important;
  }

  .seqrops-property-fields {
    input {
      background-color: #111217 !important;
      border: 1px solid #2e374b !important;
      color: #ccccdc !important;

      ::placeholder {
        color: #ccccdc !important;
      }
    }

    input:focus {
      border-color: white !important;
    }
  }

  .p-panel-header {
    background-color: #111217 !important;
    border: 1px solid #2e374b !important;
    color: #ccccdc !important;
  }

  .p-panel-content {
    background-color: #2e374b !important;
    color: #ccccdc !important;
    border-bottom: 1px solid #111217;
    border-top: 1px solid #2e374b;
    border-left: 1px solid #2e374b;
    border-right: 1px solid #2e374b;
  }

  .form-group {
    input {
      background-color: #111217 !important;
      border: 1px solid #2e374b !important;
      color: #ccccdc !important;
    }

    input:focus {
      border-color: white !important;
    }
  }

  .seqrops-alarm-form-wrapper {
    background-color: #2e374b !important;
    color: #ccccdc !important;
  }

  .seqrops-basic-form-head-section {
    background-color: #2e374b !important;
    color: #ccccdc !important;
    border: 1px solid #2e374b;
  }

  .seqrops-alarm-form-section {
    input {
      background-color: #111217 !important;
      border: 1px solid #2e374b !important;
      color: #ccccdc !important;
    }

    input:focus {
      border-color: white !important;
    }
  }

  .seqrops-tree-item-dark {
    background-color: #24272d !important;
    border: 1px solid #555664 !important;

    .d_tree_inner {
      span {
        color: #b9bdce !important;
      }
    }
  }

  .p-dialog-header {
    background-color: #24272d !important;
    color: #b9bdce !important;
  }

  .p-dialog-content {
    background-color: #24272d !important;
    color: #b9bdce !important;
  }

  .p-dialog-footer {
    background-color: #24272d !important;
    color: #b9bdce !important;
  }

  .modal-content {
    background-color: #24272d !important;
    color: #b9bdce !important;

    .modal-header {
      border-bottom: 1px solid #555664 !important;
    }

    .modal-footer {
      border-top: 1px solid #555664 !important;
    }
  }

  .seqrops-create-instance-name-section {
    input {
      background-color: #111217 !important;
      border: 1px solid #2e374b !important;
      color: #ccccdc !important;
    }

    input:focus {
      border-color: white !important;
    }
  }

  .seqrops-create-instance-description-section {
    textarea {
      background-color: #111217 !important;
      border: 1px solid #2e374b !important;
      color: #ccccdc !important;
    }

    textarea:focus {
      border-color: white !important;
    }
  }

  .seqrops-create-instance-target-name-section {
    input {
      background-color: #111217 !important;
      border: 1px solid #2e374b !important;
      color: #ccccdc !important;
    }

    input:focus {
      border-color: white !important;
    }
  }

  .MuiPaper-root {
    background-color: #24272d !important;
    color: #b9bdce !important;
  }

  .seqrops-file-download-title-section {
    background-color: #24272d !important;
    color: #b9bdce !important;
    border: 1px solid #2e374b !important;
  }
}

.btn_unsaved {
  btn-outline {
    border: 1px solid #60abfb !important;
    color: #60abfb !important;
    background-color: #24272d !important;
  }
}
