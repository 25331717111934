.modal-content{
    padding:20px ;
}

.seqrops-create-instance-form-section{
    flex-direction: column;

    .seqrops-create-instance-name-section{
        flex-direction: column;
    }
    .seqrops-create-instance-description-section{
        flex-direction: column;
        input{
            height: 17vh;
        }

    }
}

.seqrops-selected-model-section {
    background: #FFFAFA;
    border: 1px solid #FCEAE9;
    padding: 10px;
    width: 100px;
    margin-top: 23px;
    margin-bottom: 23px;
    border-radius: 5px;
    line-height: 11px;
    color: #6B7A99;
    font-weight: 600;
}
.modal-header{
    button{
        background: none;
        border: none;
    }
}

.seqrops-create-instance-description-section{
    textarea{
        resize: none;
    }
}