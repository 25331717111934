.create_pagination {
  font-size: 14px;
  color: #6B7A99;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

 .error-message-section {
  color: red;
  font-size: 13px;
  margin-bottom: 0;
} 